import React from 'react';
import PropTypes from 'prop-types';

import {
  LIST_PAGE_KEY,
  LIST_GROUPS_PAGE_KEY,
  EVENT_PAGE_KEY,
  EXHIBITOR_PAGE_KEY,
} from 'src/pages/pagesKeys';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './LinkButton.scss';

const dataConfig = require('data/config/dataConfig.js');

/**
 * Call-to-action button
 * @param  {object} props
 */
const LinkButton = ({ config, actions, displayType, labels }) => {
  if (!config.id || !config.type || !config.label) return null;

  const action = () => {
    const { id, type } = config;
    let pageKey = LIST_PAGE_KEY;
    let payload = {
      inputs: [
        {
          id,
          dataType: type,
        },
      ],
    };

    if (type === 'papers_list') {
      pageKey = LIST_PAGE_KEY;
      payload = {
        inputs: [
          {
            parentId: id,
            parentType: dataConfig.DATA_TYPE_PAPER_CATEGORIES,
            dataType: dataConfig.DATA_TYPE_PAPERS,
          },
        ],
      };
    }

    if (type === 'event_categories') {
      pageKey = LIST_GROUPS_PAGE_KEY;
      payload = {
        input: {
          parentId: id,
          parentType: type,
        },
      };
    }
    if (type === 'events') {
      pageKey = EVENT_PAGE_KEY;
      payload = {
        id,
      };
    }
    if (type === 'exhibitors') {
      pageKey = EXHIBITOR_PAGE_KEY;
      payload = {
        id,
      };
    }

    actions.navigate(pageKey, payload);
  };

  return (
    <div className="link-btn-container">
      {labels && displayType && (
        <span className="link-btn-type">{labels.data[config.type].singular} :</span>
      )}
      <div className="link-btn" onClick={action}>
        <span className="link-btn-label">{config.label}</span>
        <GenericIcon st0Class="chevron-right-icon-st0" src="icons-svg/control/triangle-droite-gris.svg" className="link-btn-icon chevron-right-icon" />
      </div>
    </div>
  );
};

LinkButton.propTypes = {
  config: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  labels: PropTypes.object,
  displayType: PropTypes.bool,
};

export default LinkButton;
