module.exports = {
  "name": "sfpm",
  "client": "PG ORGANISATION",
  "description": "Mobile app for SFPM 2022",
  "validLangs": ["fr"],
  "version": {
    "mainVersion": "1.0.0",
    "buildVersion": {
      "iOS": "33",
      "android": "31",
      "web": "15"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "11.0",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 30,
    "androidSigning": {
      "keyAlias": "mobilespotsfpm",
      "keyStorePassword": "che1ith7peiye6Ch"
    },
    "app": "app-react"
  },
  "undeliveredFolders": ["source", "exports"],
  "crypto": false,
  "web": {}
};