var defaultConf = require('./config-default');

var BO_URL = 'sfpm2022-data.ms-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://pgorganisation2021.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'sfpm2022-node-backend-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/sfpm/sfpm2022/sfpm2022-data.ms-dev.mobile-spot.com',
  // '/home/www/safi/mom/mom0122/mom202201-data-dev.mobile-spot.com',
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  BO_SSH_PORT: '22',
  //'43122'
  DEPLOY_PATH: '/home/legacy/sfpm/sfpm2022/sfpm2022.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-45',
  projectId: '1056757301402',
  // FCM sender id
  appId: 'C3F53-D7C95',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login)
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  SSO: {
    FEATURE_ENABLED: false,
    SSO_REQUEST_URL: "https://".concat(NODE_BACKEND_DOMAIN, "/sso-request")
  },
  SOCIAL: {
    FEATURE_ENABLED: true,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social") // `${LOCAL_NODE_BACKEND_DOMAIN}/social`,

  }
});