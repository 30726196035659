import React from 'react';
import PropTypes from 'prop-types';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

const EmailRow = (props) =>
  !props.email ? null : (
    <div className="free-row">
      <div className="prop-img">
        <div className="prop-left">
          <GenericIcon
            src={'icons-svg/fiche/contact.svg'}
            st1Class="row-st1"
            st0Class="row-st0"
            className="email-row"
          />
        </div>
        <div
          className="prop-right"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            wordBreak: 'break-all',
          }}
        >
          <a className="link colored-link" href={`mailto:${props.email}`}>
            {props.email}
          </a>
        </div>
      </div>
    </div>
  );

EmailRow.propTypes = {
  email: PropTypes.string,
};

export default EmailRow;
