// Libs
import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';

// app modules
import { getValueFromStringAttribute } from 'src/core/util/JsTools';
import { set as setProfile } from 'src/core/Profile';
import { apply as applyTileClickEffect } from 'src/core/util/TileClickEffect';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { iosHackScrollTo0 } from 'src/core/util/browser';
import showPromptModal from 'src/core/util/showPromptModal';

import GenericIcon from 'src/components/generic-icon/GenericIcon';

import './ChooseProfileButtons.scss';

/**
 * Build a button
 * @param {object} conf
 * @param {object} labels
 * @param {object} actions (store connected)
 * @param {object} thenNavigateTo
 * @param {object} profilecodes
 */
const getButton = (conf, labels, actions, thenNavigateTo, profilecodes) => {
  const profile = conf.value;
  const profileLabel = getValueFromStringAttribute(labels, conf.label);
  const accessCode = profilecodes && profilecodes[profile];
  const hasAccessCode = typeof accessCode === 'string' && accessCode.length;

  function next() {
    // Set curent profile
    setProfile(profile);

    if (typeof conf.onClick === 'function') {
      conf.onClick(profile, actions, thenNavigateTo);
    }
  }

  function onClick(e) {
    applyTileClickEffect(e.target, 'choose-profile-button', () => {
      if (!hasAccessCode) {
        next();
      } else {
        // Ask user input for code
        showPromptModal({
          title: `${labels.profile.title} - ${profileLabel}`,
          text: labels.profile.codeInput,
          validateCb: (input) => {
            if (typeof input !== 'string') {
              // user probably cancelled the action (modal discarded)
              // ignore
            } else if (md5(input) === accessCode) {
              next();
            } else {
              actions.showNotification({
                message: labels.profile.invalidCode,
                level: NotificationLevels.ERROR,
              });
            }
          },
        });
      }
    });
  }

  return (
    <div key={conf.value} className="choose-profile-button" onClick={onClick} {...conf.props}>
      <span className="profile-button-label-container">
        <span className="profile-button-label">{profileLabel}</span>
        {hasAccessCode && <GenericIcon className="profile-lock-icon lock-icon" />}
        <GenericIcon st0Class="chevron-right-icon-st0" src="icons-svg/control/triangle-droite-gris.svg"  className="profile-button-icon chevron-right-icon" />
      </span>
      <span className="effect-container" />
    </div>
  );
};

/**
 * Component
 */
const ChooseProfileButtons = (props) => (
  <div id="profile-buttons">
    {props.config.map((conf) =>
      getButton(conf, props.labels, props.actions, props.thenNavigateTo, props.profilecodes)
    )}
  </div>
);

ChooseProfileButtons.propTypes = {
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  config: PropTypes.array.isRequired,
  profilecodes: PropTypes.object,
  thenNavigateTo: PropTypes.object,
};

export default ChooseProfileButtons;
