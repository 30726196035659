import React from 'react';
import PropTypes from 'prop-types';

import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';

import config from 'data/config/config';
import isOnline from 'src/core/util/isOnline';
import { isAndroid } from 'src/core/util/browser';

import { isSessionValid } from 'src/core/login/LoginService';

import './DetailDocument.scss';

/**
 * Common component to display places
 * @param  {array} places
 */
const DetailDocument = ({ document, labels, actions }) => {
  // don't display anything if no document defined
  if (!document) {
    return null;
  }

  // don't display anything if pdf access is restricted and login feature is not enabled
  if (document.restricted && !config.LOGIN.FEATURE_ENABLED) {
    return null;
  }

  const action = () => {
    if (document.restricted && !isSessionValid()) {
      return actions.navigate(LOGIN_PAGE_KEY);
    }

    isOnline((result) => {
      if (result) {
        window.open(decodeURI(document.link), isAndroid() ? '_system' : '_blank');
      } else {
        actions.showNotification({ message: labels.common.noNetworkTitle });
      }
    });
  };

  return (
    <div className="fiche-related-data">
      <div className="prop-img">
        <div className="prop-left">
          <div className="icon-font">
            <GenericIcon
              onClick={action}
              src="icons-svg/fiche/groupe.svg"
              className="link-icon-font-icon"
            ></GenericIcon>
          </div>
        </div>
        <div className="prop-right readPdf">
          <span className="colored-link" onClick={action}>
            {document.label || labels.paper.openPdf}
          </span>
        </div>
      </div>
    </div>
  );
};

DetailDocument.propTypes = {
  document: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default DetailDocument;
